import { Controller } from "@hotwired/stimulus"
import LoadMoreController from "./load_more"
import ReaderPostController from "./reader_post"

export default class extends Controller {
    static targets = [ ]
    static outlets = [ "load-more", "reader-post" ]
    static values = {}

    declare readonly postTargets: HTMLElement[]
    declare readonly loadMoreOutlet: LoadMoreController
    declare readonly readerPostOutlets: ReaderPostController[]

    observer: IntersectionObserver | null
    viewed: Set<string>
    skip: Set<string>

    initialize(): void {
        this.skip = new Set()
        this.initObserver()
    }

    connect() {
        this.initObserver()
        this.updateSkipSet()
    }

    disconnect(): void {
        if (this.observer) { this.observer.disconnect() }
        this.observer = null
    }


    initObserver() {
        if (this.observer) { return }
        this.viewed = new Set()
        this.observer = new IntersectionObserver((ents) => {
            ents.forEach((ent) => {
                let post = this.application.getControllerForElementAndIdentifier(
                    ent.target.parentElement!,
                    "reader-post"
                )
                if (!(post instanceof ReaderPostController)) { return }

                if (ent.isIntersecting && !this.viewed.has(post.idValue)) {
                    this.viewed.add(post.idValue)
                } else if (this.viewed.has(post.idValue) && ent.boundingClientRect.top < 0) {
                    this.handleLeftView(ent.target, post)
                }
            })
        })
        this.readerPostOutlets.forEach((post) => {
            if (this.observer) { this.observer.observe(post.markerTarget) }
        })
    }

    handleLeftView(elem: Element, post: ReaderPostController) {
        if (!this.observer) { return }
        this.observer.unobserve(elem)

        //console.log("ID:", post.idValue, "Post:", post.element, post)
        post.sendRead()
    }


    readerPostOutletConnected(post: ReaderPostController) {
        this.updateSkipSet()
        if (this.observer) { this.observer.observe(post.markerTarget) }
    }

    updateSkipSet() {
        this.skip.clear()
        this.readerPostOutlets.forEach(post => {
            let id = post.idValue
            if (id) { this.skip.add(id) }
        })
        this.updateSkipQuery()
    }

    updateSkipQuery() {
        var skipStr = ""
        this.skip.forEach(id => {
            skipStr += id + ","
        })
        skipStr = skipStr.slice(0, -1)
        this.loadMoreOutlet.setExtraQuery("&skip=" + skipStr)
    }

    loadMoreOutletConnected(_: LoadMoreController) {
        this.updateSkipQuery()
    }
}

