export function initObserver() {
    let obs = new MutationObserver(() => {
        connectButtons()
    })
    obs.observe(document, {
        subtree: true,
        childList: true,
    })
    connectButtons()
}

function connectButtons() {
    let btns = document.querySelectorAll(".button:not(.ripple)")
    for (let btn of btns) {
        if (btn instanceof HTMLElement) {
            connectButton(btn)
            btn.classList.add('ripple')
        }
    }
}

function connectButton(elem: HTMLElement) {
    elem.addEventListener('click', createRipple)
}

function createRipple(event: MouseEvent) {
    let button = event.target
    if (button && button instanceof HTMLElement) {
        if (button.classList.contains('icon')) {
            button = button.parentElement
        }
    }
    console.log("Ripple on", button)
    if (button && button instanceof HTMLElement) {
        let rect = button.getBoundingClientRect()
        button.style.setProperty('--x', `${event.clientX - rect.x}px`)
        button.style.setProperty('--y', `${event.clientY - rect.y}px`)
        button.classList.add('ani')
        addEventListener('animationend', e => {
            if (button.classList.contains('ani')) {
                button.classList.remove('ani')
            }
        })
    }
}

