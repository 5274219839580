import { Controller } from "@hotwired/stimulus"
import { scrollIntoView } from "../scroll"

const COLLAPSE_ANIM_TIME = 200
const EXPAND_ANIM_TIME = 500
const HIGHLIGHT_TIME = 1000

export default class extends Controller {
    static targets = ["toggle", "aria", "height"];
    animTimer: number | null

    declare readonly toggleTargets: HTMLElement[]
    declare readonly ariaTarget: HTMLElement

    // When collapsed, the element will match the height of this one
    declare readonly heightTarget: HTMLElement

    fullHeight: number

    connect(): void {
        // Add the `clickable` class so the CSS can account for whether
        // JavaScript is enabled or not.
        for (let targ of this.toggleTargets) {
            targ.classList.add("clickable")
        }
    }

    toggle(ev: PointerEvent) {
        if (this.animTimer) {
            clearTimeout(this.animTimer)
            this.animTimer = null
        }

        if (this.element.classList.contains("collapse")) {
            this.expand(ev)
        } else {
            this.collapse(ev)
        }
    }

    expand(_ev: PointerEvent) {
        (this.element as HTMLElement).style.maxHeight = `${this.fullHeight}px`
        this.element.classList.remove("collapse")
        this.ariaTarget.ariaExpanded = "true"
        // Wait until after the animation is done, then reset the element
        this.animTimer = setTimeout(() => {
            (this.element as HTMLElement).style.removeProperty("max-height")
            this.element.classList.remove("animate")
            this.animTimer = null
        }, EXPAND_ANIM_TIME)
    }

    collapse(ev: PointerEvent) {
        if (!this.fullHeight) {
            this.fullHeight = this.element.clientHeight
        }
        // Set up initial max height and css class to prepare animations
        (this.element as HTMLElement).style.maxHeight = `${this.fullHeight}px`
        this.element.classList.add("animate")
        this.ariaTarget.ariaExpanded = "false"
        // Then wait a bit and collapse the element
        setTimeout(() => {
            let foldHeight = this.heightTarget.offsetHeight;
            (this.element as HTMLElement).style.maxHeight = `${foldHeight}px`
            this.element.classList.add("collapse")
            this.animTimer = null
        }, 1)

        // Scroll so the element's top is at the mouse position
        let elemTop = (this.element as HTMLElement).offsetTop + 20
        if (scrollIntoView(elemTop, ev.clientY)) {
            // If we scrolled, highlight the collapsed post visually for a
            // short time just as the animation finishes
            setTimeout(() => { this.highlight() }, COLLAPSE_ANIM_TIME)
        }
    }

    /// Highlight the element visually for a short time
    highlight() {
        this.element.classList.add("highlight")
        setTimeout(() => {
            this.element.classList.remove("highlight")
        }, HIGHLIGHT_TIME)
    }

    hoverIn() {
        for (let target of this.toggleTargets) {
            target.classList.add("hover")
        }
    }

    hoverOut() {
        for (let target of this.toggleTargets) {
            target.classList.remove("hover")
        }
    }
}

