import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
    static component = "media"
    static targets = [ "media", "link" ]

    declare readonly mediaTargets: HTMLElement[]

    connect() {
        console.log("Media bridge connected for post", this.element.dataset['post'])

        let processTarget = (target: HTMLElement) => {
            if (target instanceof HTMLImageElement) {
                return { type: 'image', url: target.src }
            } else if (target instanceof HTMLVideoElement) {
                return this.handleVideo(target)
            } else {
                return null
            }
        }
        let post = this.element.dataset['post']
        let media = this.mediaTargets.map(processTarget).filter((el) => el != null)
        this.send("post", { post, media }, () => { })
    }

    handleVideo(target: HTMLVideoElement) {
        console.log("Video target: ", target)
        
        // Replace the video with an image which opens the media viewer
        let parent = target.parentElement
        if (parent) {
            let img = document.createElement('img')
            img.classList.add('bridged-video')
            img.src = target.poster
            console.log("Poster", target.poster)
            img.alt = "Click to play video"
            img.setAttribute('width', target.attributes['width'].value)
            img.setAttribute('height', target.attributes['height'].value)
            let link = document.createElement('a')
            link.href = `/media/post/${this.element.dataset['post']}`
            link.appendChild(img)
            parent.insertBefore(link, target)
            parent.removeChild(target)
        }

        let source = target.getElementsByTagName('source')[0]
        return {
            type: 'video',
            url: source.src,
            hls: target.dataset.hlsUrlValue,
        }
    }
}

