import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
    static component = "nav"
    static targets = [ "feed", "label" ]

    declare readonly feedTargets: HTMLElement[]
    declare readonly labelTargets: HTMLElement[]

    connect() {
        console.log("Nav connected")
        console.log("Feed targets: ", this.feedTargets)
        console.log("Label targets: ", this.labelTargets)

        let processTarget = (target: HTMLElement) => {
            if (target instanceof HTMLAnchorElement) {
                return { title: target.textContent, url: target.href }
            } else {
                return null
            }
        }
        let feeds = this.feedTargets.map(processTarget).filter((el) => el != null)
        let labels = this.labelTargets.map(processTarget).filter((el) => el != null)
        this.send("update", { feeds, labels }, () => {
            console.log("Reply received")
        })
    }
}
