import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        time: Number
    }

    declare readonly timeValue: number
    declare readonly hasTimeValue: boolean

    connect() {
        if (this.hasTimeValue) {
            setTimeout(() => this.close(), this.timeValue * 1000)
        }
    }

    close() {
        this.element.parentNode?.removeChild(this.element)
    }
}

