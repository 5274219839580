import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "button", "marker" ]
    static values = { "id": String }

    declare readonly idValue: string
    declare readonly hasButtonTarget: boolean
    declare readonly buttonTarget: HTMLElement
    declare readonly markerTarget: HTMLElement

    // Actually request the backend mark as read by clicking the read button.
    sendRead() {
        if (!this.hasButtonTarget) { return }
        if (!this.buttonTarget.classList.contains("active")) {
            this.buttonTarget.click()
        }
    }
}
