import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    observer: IntersectionObserver

    connect() {
        this.observer = new IntersectionObserver( 
            ([e]) => {
                e.target.classList.toggle("stuck", e.intersectionRatio < 1)
                console.log("Test")
            },
            { threshold: [1] }
        );

        console.log("Observing")
        this.observer.observe(this.element);
    }

    close() {
        this.observer.disconnect()
    }
}

