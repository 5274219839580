import { Controller } from "@hotwired/stimulus"

const LOADING_HTML = '<div class="loading"><div class="lds-ripple"><div></div><div></div></div></div>'
const FULLPAGE_LOADING_HTML = `<div class="full-center" data-turbo-temporary>${LOADING_HTML}</div>`

export class LoadingController extends Controller {
    static targets = [ "replace", "append" ]

    declare readonly replaceTargets: HTMLElement[]
    declare readonly appendTargets: HTMLElement[]

    start() {
        for (let target of this.replaceTargets) {
            target.innerHTML = LOADING_HTML
        }
        for (let target of this.appendTargets) {
            target.innerHTML += LOADING_HTML
        }
    }
}

var loadingTimeout: number | null = null

function stopLoadTimer() {
    if (loadingTimeout != null) {
        clearTimeout(loadingTimeout)
        loadingTimeout = null
    }
}

export class PreviewLoadingController extends Controller {
    connect(): void {
        stopLoadTimer()
        if (document.documentElement.hasAttribute('data-turbo-preview')) {
            this.element.innerHTML = LOADING_HTML
        }
    }
}

export function initMainReplacement() {
    if (window.navigator.userAgent.startsWith('Turbo Native')) {
        return
    }
    document.addEventListener('turbo:before-visit', () => {
        loadingTimeout = setTimeout(replaceMain, 80)
    })
    document.addEventListener('turbo:before-fetch-response', stopLoadTimer)
}

function replaceMain() {
    // Don't show a whole-page loading animation if turbo has a preview cached
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    let main = document.querySelector('.container')
    if (main) {
        main.innerHTML = FULLPAGE_LOADING_HTML + main.innerHTML
    }
}
