import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
    static component = "toolbar"
    static targets = [ "primary", "button" ]

    declare readonly buttonTargets: HTMLElement[]

    connect() {
        console.log("Toolbar connected")
        this.send("init", {}, () => {})

        for (let button of this.buttonTargets.reverse()) {
            let icon = button.dataset['icon']
            this.send(button.id, {
                label: button.textContent,
                icon: icon ? icon : null,
            }, () => {
                console.log("Reply received for ", button)
                button.click()
            })
        }
    }
}
