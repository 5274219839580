import { Controller } from "@hotwired/stimulus"
import { Hls } from "hls.js"

export default class extends Controller {
    static values = { url: String }

    declare readonly urlValue: string

    connect() {
        var video = this.element
        if (Hls.isSupported()) {
            var hls = new Hls();
            hls.loadSource(this.urlValue);
            hls.attachMedia(video);
        }
        // HLS.js is not supported on platforms that do not have Media Source
        // Extensions (MSE) enabled.
        //
        // When the browser has built-in HLS support (check using `canPlayType`),
        // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
        // element through the `src` property. This is using the built-in support
        // of the plain video element, without using HLS.js.
        else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = this.urlValue;
        }
    }
}

