import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "button" ]
    static values = {
        embed: String,
        title: String,
    }

    declare readonly hasButtonTarget: boolean
    declare readonly buttonTarget: HTMLElement

    elem: HTMLElement | null

    connect(): void {
        this.elem = null
    }

    // Actually request the backend mark as read by clicking the read button
    send() {
        if (!this.hasButtonTarget) { return }
        if (!this.buttonTarget.classList.contains("active")) {
            this.buttonTarget.click()
        }
    }

    // Mark read visually on frontend only
    //
    // Use when already doing an action that will mark the post as read on the
    // backend, but not visually on the frontend (i.e., when navigating to the
    // post's page)
    mark() {
        this.element.classList.add("read")
        this.buttonTarget.classList.add("active")
        this.buttonTarget.ariaChecked = "true"
    }
}
