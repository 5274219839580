// Scrolling utilities

const SCROLL_TIME = 500

// Rough value larger than the navbar height to offset the window top
const NAV_HEIGHT = 100

/**
 * Scrolls the viewport so that the Y coordinate on the page given by `pos` is
 * at the viewport Y coordinate specified by `offset`, unless `pos` is already
 * visible on screen, in which case this does nothing.
 *
 * Returns true if we scrolled
 */
export function scrollIntoView(pos: number, offset: number): boolean {
    let win_top = window.scrollY - NAV_HEIGHT
    let win_bottom = window.scrollY + window.innerHeight
    if (win_top < pos && pos < win_bottom) { return false }

    // Enable smooth scrolling first
    document.documentElement.classList.add("smooth")

    window.scrollTo(0, pos - offset)
    setTimeout(() => {
        document.documentElement.classList.remove("smooth")
    }, SCROLL_TIME)

    return true
}

