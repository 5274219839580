import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"
import "@hotwired/strada"

Turbo.start()

// Correct turbo's stupid behavior when we want to break out of a frame with a redirect.
document.addEventListener("turbo:frame-missing", event => {
  if (event.detail.response.redirected) {
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }
})

document.addEventListener('turbo:before-fetch-response', (ev: any) => {
    let alerts = document.getElementById("alerts")
    if (alerts && alerts instanceof HTMLElement) {
        let res = ev.detail.fetchResponse.response as Response
        if (!res.ok) {
            res.text().then((text) => {
                if (alerts) { showError(alerts, res, text) }
            })
        }
    }
})
document.addEventListener('turbo:fetch-request-error', (_ev: any) => {
    let alerts = document.getElementById("alerts")
    if (alerts) {
        showError(alerts, null, "Failed to send request to the server")
    }
})

function showError(alerts: HTMLElement, res: Response | null, text: string) {
    try {
        let json = JSON.parse(text)
        text = json['error']
    } catch (e) { } // Not JSON, just show the whole text

    let alert = document.createElement("div")
    alert.role = "alert"
    alert.className = "alert bad"
    alert.dataset.controller = "alert"
    alert.dataset.alertTimeValue = "5"

    // TODO: i18n
    let title = document.createElement("h1")
    title.textContent = "Error"
    alert.appendChild(title)

    let body = document.createElement("p")
    if (res && text == "") {
        body.textContent = `Server returned ${res.status}: ${res.statusText}`
    } else {
        body.textContent = text
    }
    alert.appendChild(body)
    alerts?.appendChild(alert)
}

import { initObserver } from "./ripple"
initObserver()


let app = Application.start()
window.Stimulus = app

console.log("Registering")
import PostReadController from "./controllers/post_read"
app.register("post-read", PostReadController)
import LoadMoreController from "./controllers/load_more"
app.register("load-more", LoadMoreController)
import ExpanderController from "./controllers/expander"
app.register("expander", ExpanderController)
import ReaderController from "./controllers/reader"
app.register("reader", ReaderController)
import ReaderPostController from "./controllers/reader_post"
app.register("reader-post", ReaderPostController)
import AlertController from "./controllers/alert"
app.register("alert", AlertController)
import StickyController from "./controllers/sticky"
app.register("sticky", StickyController)
import HlsController from "./controllers/hls"
app.register("hls", HlsController)

import { LoadingController, PreviewLoadingController, initMainReplacement } from "./controllers/loading"
app.register("loading", LoadingController)
app.register("preview-loading", PreviewLoadingController)
initMainReplacement()


import NavBridgeController from "./bridge/nav"
app.register("bridge--nav", NavBridgeController)
import ToolbarBridgeController from "./bridge/toolbar"
app.register("bridge--toolbar", ToolbarBridgeController)
import MediaBridgeController from "./bridge/media"
app.register("bridge--media", MediaBridgeController)
