import * as Turbo from "@hotwired/turbo"
import { Controller } from "@hotwired/stimulus"

// Pixels from the bottom before we load
const MARGIN = 100

export default class extends Controller {
    static targets = ["ani", "error"]
    static values = {
        url: String,
        loading: Boolean,
    }

    declare loadingValue: boolean
    declare readonly urlValue: string
    declare readonly aniTarget: HTMLElement
    declare readonly errorTarget: HTMLElement

    observer: IntersectionObserver
    extraQuery: string = ""

    load() {
        if (this.loadingValue) { return }
        this.loadingValue = true
        this.aniTarget.classList.remove("hidden")
        fetch(this.urlValue + this.extraQuery)
            .then((res) => res.text())
            .then((html) => {
                Turbo.renderStreamMessage(html)
            })
            .catch((err: any) => this.error(err))
    }

    error(err: any) {
        console.error("Failed loading infinite scrolling:", err)
        this.errorTarget.classList.remove("hidden")
        this.aniTarget.classList.add("hidden")
    }

    retry() {
        this.errorTarget.classList.add("hidden")
        this.loadingValue = false
        this.load()
    }

    connect(): void {
        this.observer = new IntersectionObserver((ents) => {
            if (ents[0].isIntersecting) { this.load() }
        }, { rootMargin: `0px 0px ${MARGIN}px 0px` })
        this.observer.observe(this.element)
    }

    disconnect(): void {
        this.observer.disconnect()
    }

    setExtraQuery(query: string) {
        this.extraQuery = query
    }
}

